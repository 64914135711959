/*
**********************************************************
* Note: Use 'rem' all over the wesbite 1rem = 10px = 62.5%
**********************************************************
*/

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: $color-light-grey;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $color-black;
}

/* Unset text selection */
::-moz-selection {
    color: unset;
    background: unset;
}

.Toastify__toast-body {
    flex: unset;
    text-align: start;
}

.lazy-load-image-background {
    display: initial !important;
    background: transparent !important;
}

.btn {
    &.btn-primary {
        box-shadow: none;
        outline: none;
        border: none;

        &:hover,
        &:focus {
            border: none;
            outline: none;
            box-shadow: none;
        }
    }
}

input,
.form-control {
    outline: none;
    box-shadow: none;
    border-radius: 10px;
    color: $color-black;
    border-color: $color-light-grey;

    &::placeholder {
        display: none;
    }

    &:hover,
    &:focus {
        color: $color-black;
        outline: none;
        box-shadow: none;
        border-color: $color-green;
    }
}

.form-check-input {
    border: none;
    outline: none;
    box-shadow: none;

    &:hover,
    &:focus {
        border: none;
        outline: none;
        box-shadow: none;
    }
}

.container,
.container-fluid {
    max-width: 1560px;
    padding: 0 1.5rem;
}

// react select
.react-select {
    position: relative;

    &:hover,
    &:focus {
        .icon-wrapper {
            background-color: $color-green;

            svg {
                fill: $color-white;

                path {
                    fill: $color-white;
                }
            }
        }
    }

    .react-select-container__control {
        border: none;
        outline: none;
        box-shadow: none;
        min-height: 44px;
        padding-left: 46px;
        text-align: start;
        background-image: url("data:image/svg+xml,%0A%3Csvg width='5' height='6' viewBox='0 0 12 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.55048 5.44972L12 0.000199747L5.68447 0.000200977L-2.48173e-07 0.000200748L5.44952 5.44972C5.75354 5.75374 6.24646 5.75374 6.55048 5.44972Z' fill='%23101010'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-size: 12px;
        background-position: 98% 59%;
    }

    .react-select-container__indicator {
        svg {
            display: none;
        }
    }

    .react-select-container__menu {
        text-align: start;
    }

    .react-select-container__indicator-separator {
        background-color: transparent;
    }

    .react-select-container__placeholder {
        color: $color-dark-grey;
    }

    .icon-wrapper {
        transition: 0.3s ease all;
        position: absolute;
        z-index: 1;
        top: 12px;
        left: 12px;
        min-width: 35px;
        height: 35px;
        background-color: #EFEFEF;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
}



//  custom margin
.mt-20 {
    margin-top: 20px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mt-30 {
    margin-top: 30px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mt-40 {
    margin-top: 40px;
}

.mb-40 {
    margin-bottom: 40px;
}

.mt-80 {
    margin-top: 80px;
}

.mb-80 {
    margin-bottom: 80px;
}

.mt-0-2 {
    margin-top: 0.2rem;
}

// custom padding

.pt-20 {
    padding-top: 20px;
}

.pb-20 {
    padding-bottom: 20px;
}

.pt-30 {
    padding-top: 30px;
}

.pb-30 {
    padding-bottom: 30px;
}

.pt-40 {
    padding-top: 40px;
}

.pb-40 {
    padding-bottom: 40px;
}

.pb-40 {
    padding-bottom: 40px;
}

.px-40 {
    padding: 0 40px;
}
/* XXL devices */
@media only screen and (max-width: 1400px) {
    .setting-sidebar {
        height: 72vh;
    }
}

/* XL devices */
@media only screen and (max-width: 1200px) {
    .massonary-grid {
        columns: 3;

        .massonary-card {
            &:nth-child(odd) {
                .post-img {
                    height: 250px;
                }
            }

            &:nth-child(even) {
                .post-img {
                    height: 450px;
                }
            }
        }
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
    .auth-layout {
        .auth-card {

            &.account-verification-form,
            &.password-verification-form {
                .otp-input {
                    input {
                        height: 45px;
                    }
                }
            }
        }
    }

    .added-post-card {
        .btn-solid {
            &.done-btn {
                padding: 1rem;
            }
        }
    }

    .massonary-grid {
        columns: 3;

        .massonary-card {
            &:nth-child(odd) {
                .post-img {
                    height: 240px;
                }
            }

            &:nth-child(even) {
                .post-img {
                    height: 360px;
                }
            }
        }
    }
}

/* Medium devices */
@media only screen and (max-width: 768px) {
    .auth-layout {
        .auth-card {

            &.account-verification-form,
            &.password-verification-form {
                .otp-input {
                    input {
                        height: 50px;
                    }
                }
            }
        }
    }

    .added-post-card {
        .nav {
            &.nav-pills {
                .nav-link {
                    margin: 0;
                }
            }
        }
    }

    .massonary-grid {
        columns: 3;

        .massonary-card {
            &:nth-child(odd) {
                .post-img {
                    height: 165px;
                }
            }

            &:nth-child(even) {
                .post-img {
                    height: 250px;
                }
            }
        }
    }
}

/* Small devices */
@media only screen and (max-width: 576px) {
    .theme-popup {
        &.notification-popup {
            .modal-content {
                max-width: 100%;
                transform: unset;
            }

            .notification-wrapper {
                .box {
                    .btn-solid {
                        padding: 8px 16px;
                    }
                }
            }
        }

        &.message-popup {
            .modal-content {
                max-width: 100%;
                transform: unset;
            }
        }

        &.profile-popup {
            .modal-content {
                max-width: 100%;
                transform: unset;
            }
        }

        &.calendar-popup {
            .react-calendar__month-view {
                font-size: 12px !important;
            }

            .react-calendar__tile--now {
                border-radius: 0;
            }

            .react-calendar__month-view__days {
                .react-calendar__tile {
                    &:hover {
                        border-radius: 0;
                    }
                }

                button {
                    height: auto;
                }

                .react-calendar__tile--active {
                    border-radius: 0;
                }

                .theme-popup.month-filter-popup .react-calendar__month-view__days .react-calendar__tile--now {
                    border-radius: 0;
                }
            }

            .time-picker-wrapper {
                .ampm-box {
                    label {
                        padding: 0.25rem 0.8rem;
                    }
                }
            }
        }

        &.calendar-popup {
            .modal-dialog {
                max-width: 100%;
            }
        }

        &.filter-popup {
            .modal-dialog {
                max-width: 100%;
            }
        }

        &.add-folder-popup,
        &.block-alert-popup,
        &.unblock-alert-popup,
        &.delete-account-popup,
        &.logout-popup {
            .modal-dialog {
                max-width: 100%;
            }
        }
    }

    .edit-profile-form {
        .px-40 {
            padding: 0 0;
        }
    }

    .change-password-form {
        padding: 30px 25px;
    }

    .create-post-box,
    .edit-profile-form,
    .block-user-card,
    .create-edit-post-form {
        width: 90%
    }

    .added-post-card {
        .nav {
            &.nav-pills {
                justify-content: start;
            }
        }
    }

    .stats-card {
        .box {
            width: 100%;

            span {
                font-size: 12px;
            }
        }

        .icon-wrapper {
            min-width: 60px;
            width: 60px;
            height: 60px;
        }
    }

    .theme-tabs {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .nav-item {
            &:nth-child(odd) {
                border-left: none;
            }

            &:last-child {
                border-left: none;
            }

            &:nth-child(1) {
                border-left: none !important;
            }

            &:nth-child(2) {
                border-left: none;
            }
        }
    }

    .massonary-grid {
        columns: 2;

        .massonary-card {
            &:nth-child(odd) {
                .post-img {
                    height: 250px;
                }
            }

            &:nth-child(even) {
                .post-img {
                    height: 250px;
                }
            }
        }
    }

    .search-popup {
        .mt-1-5 {
            margin-top: 0.15rem;
        }

        .search-wrapper {
            .search-box {
                .form-control {
                    padding: 8px 15px 5px 0 !important;
                    background-image: none;
                }
            }
        }
    }

    .auth-card {
        padding: 30px 25px;

        &.select-role-form {
            .wrapper {
                label {
                    padding: 1.5rem 1rem;
                }

                .form-check-input {
                    right: 1rem;
                }
            }
        }
    }

    .rollup-card {
        .rollup-card-details {
            flex-direction: column;
            align-items: flex-start;

            .main-rollup-card-img {
                min-width: 100%;
                height: auto;

                img{
                    object-fit: unset;
                }
            }
        }
    }
}

/* Extra small devices */
@media only screen and (max-width: 460px) {
    .auth-layout {
        .auth-card {

            &.account-verification-form,
            &.password-verification-form {
                .otp-input {
                    input {
                        height: 50px;
                    }
                }
            }
        }
    }

    .massonary-grid {
        columns: 2;

        .massonary-card {
            &:nth-child(odd) {
                .post-img {
                    height: 220px;
                }
            }

            &:nth-child(even) {
                .post-img {
                    height: 220px;
                }
            }
        }
    }
}

/* Extra small devices */
@media only screen and (max-width: 400px) {

    .massonary-grid {
        columns: 2;

        .massonary-card {
            &:nth-child(odd) {
                .post-img {
                    height: 185px;
                }
            }

            &:nth-child(even) {
                .post-img {
                    height: 185px;
                }
            }
        }
    }
}

/* Extra small devices */
@media only screen and (max-width: 375px) {
    .auth-card {
        &.select-role-form {
            .wrapper {
                label {
                    font-size: 14px;
                }
            }
        }
    }

    .view-profile-card {
        &.view-myprofile-card {
            .btn-solid {
                &.follow-btn {
                    padding: 9px 30px;
                }
            }
        }
    }
}

/* Extra small devices */
@media screen and (max-width: 350px) {
    .added-post-card {
        .nav {
            &.nav-pills {
                .nav-item {
                    width: 50%;
                }

                .nav-link {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
    }

    .massonary-grid {
        columns: 2;

        .massonary-card {
            &:nth-child(odd) {
                .post-img {
                    height: 170px;
                }
            }

            &:nth-child(even) {
                .post-img {
                    height: 170px;
                }
            }
        }
    }
}
.Nord-Regular {
    font-family: "Nord-Regular";
    font-weight: normal;
    font-style: normal;
}

.Outfit-Regular {
    font-family: "Outfit-Regular";
    font-weight: normal;
    font-style: normal;
}

h1 {
    font-size: 32px;
    font-weight: 500;
    margin: 0;
    @extend .Outfit-Regular;
}

h2 {
    font-size: 24px;
    font-weight: 500;
    margin: 0;
    @extend .Outfit-Regular;
}

h3 {
    font-size: 18px;
    font-weight: 700;
    margin: 0;
    @extend .Nord-Regular;
}

h4 {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
    @extend .Outfit-Regular;
}

h5 {
    font-size: 18px;
    font-weight: 700;
    margin: 0;
    @extend .Nord-Regular;
}

h6{
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    @extend .Outfit-Regular
}

p,
input,
.form-control,
small {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    @extend .Outfit-Regular;
}

input,
.form-control {
    &::placeholder {
        font-size: 14px;
        font-weight: 400;
        @extend .Outfit-Regular;
    }
}

.form-floating {
    > label {
        opacity: 1 !important;
        color: $color-dark-grey;
        font-size: 14px;
        font-weight: 400;
        @extend .Outfit-Regular;
    }
}

span {
    font-size: 16px;
    font-weight: 400;
    @extend .Outfit-Regular;
}

button.btn,
a {
    font-size: 16px;
    font-weight: 700;
    @extend .Nord-Regular;
}

ul {
    li {
        font-size: "";
        font-weight: "";
    }
}

.auth-card {
    text-align: center;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
    border-radius: 25px;
    width: 525px;
    margin: auto;
    padding: 30px 50px;

    .form-floating > .form-control:not(:placeholder-shown) ~ label{
        left: 6px;
    }

    .text-black {
        color: $color-black !important;
    }

    a {
        &.link {
            color: $color-black;
            text-decoration: none;
        }
    }

    .logo-wrapper {
        margin: auto;
        width: 60px;
        height: 75px;

        &.large {
            width: 180px;
            height: 167px;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }

    h3 {
        color: $color-black;
    }

    .select-div {
        border: 1px solid $color-light-grey;
        border-radius: 10px;
        height: 60px;
        transition: 0.3s ease all;
        overflow: hidden;
        padding: 1rem 12px;

        &:has(input:hover) {
            .icon-wrapper {
                background-color: $color-green;

                svg {
                    fill: $color-white;

                    path {
                        fill: $color-white;
                    }
                }
            }
        }

        &:hover,
        &:focus {
            border-color: $color-green;

            .icon-wrapper {
                background-color: $color-green;

                svg {
                    fill: $color-white;

                    path {
                        fill: $color-white;
                    }
                }
            }
        }

        .form-floating {
            padding: 0 2px;
        }

        .icon-wrapper {
            min-width: 35px;
            height: 35px;
            background-color: $color-grey;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
        }

        .right-wrapper {
            padding-left: 15px;

            label {
                padding: 0 !important;
                position: absolute;
                top: 12px;
            }
        }

        .form-control {
            border: none;
            padding-top: 0 !important;
        }
    }


    .form-floating {
        position: relative;

        .form-select {
            background-image: url("data:image/svg+xml,%0A%3Csvg width='5' height='6' viewBox='0 0 12 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.55048 5.44972L12 0.000199747L5.68447 0.000200977L-2.48173e-07 0.000200748L5.44952 5.44972C5.75354 5.75374 6.24646 5.75374 6.55048 5.44972Z' fill='%23101010'/%3E%3C/svg%3E%0A");
            background-size: 12px;
            background-position-y: 53%;
        }

        .form-select,
        input {
            padding-left: 61px;
            transition: 0.3s ease all;

            &:hover,
            &:focus {
                ~.icon-wrapper {
                    background-color: $color-green;

                    svg {
                        fill: $color-white;

                        path {
                            fill: $color-white;
                        }
                    }
                }
            }
        }

        label {
            top: 2px;
            padding-left: 62px;
            left: 6px;
        }

        .icon-wrapper {
            transition: 0.3s ease all;
            position: absolute;
            top: 12px;
            left: 12px;
            min-width: 35px;
            height: 35px;
            background-color: $color-grey;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;

            .icon {
                transition: 0.3s ease all;
                color: $color-green;
                font-size: 14px;
            }
        }

        .icon-wrapper-right {
            position: absolute;
            top: 13px;
            right: 12px;
            min-width: 15px;
            min-height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;

            .icon {
                color: $color-green;
            }
        }
    }

    &.select-role-form {
        h3 {
            color: $color-deep-grey;
        }

        .wrapper {
            display: flex;
            align-items: center;
            flex-flow: row-reverse;
            justify-content: space-between;
            position: relative;

            label {
                cursor: pointer;
                width: 100%;
                font-size: 16px;
                font-weight: 700;
                border-radius: 10px;
                padding: 2rem 1.5rem;
                border: 1px solid $color-light-grey;
                text-align: left;
                height: 110px;
                display: flex;
                align-items: center;
                @extend .Nord-Regular;
            }

            .form-check-input {
                position: absolute;
                top: 50%;
                right: 1.5rem;
                width: 25px;
                height: 25px;
                transform: translateY(-50%);
                background: $color-light-grey;

                &:checked {
                    background-image: url("data:image/svg+xml,%0A%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12.5' cy='12.5' r='12.5' fill='%23525D59'/%3E%3Cpath d='M10.8431 16.0714C10.609 16.0714 10.3749 15.9852 10.1901 15.8004L8.20645 13.8168C7.84915 13.4595 7.84915 12.8681 8.20645 12.5108C8.56375 12.1535 9.15513 12.1535 9.51243 12.5108L10.8431 13.8414L15.4879 9.19655C15.8452 8.83925 16.4366 8.83925 16.7939 9.19655C17.1512 9.55384 17.1512 10.1452 16.7939 10.5025L11.496 15.8004C11.3112 15.9852 11.0771 16.0714 10.8431 16.0714Z' fill='white'/%3E%3C/svg%3E%0A");
                    background-repeat: no-repeat;

                    ~label {
                        background-color: red;
                        border: 5px solid $color-green;
                    }
                }
            }
        }
    }

    &.signup-form {
        .profile-img-wrapper {
            min-width: 100px;
            width: 100px;
            height: 100px;
            border-radius: 50%;
            overflow: hidden;
            cursor: pointer;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                border-radius: 50%;
            }
        }
    }

    &.signin-form {
        .line-text {
            margin: 32px auto;
            position: relative;
            text-align: center;
            width: 100%;

            .text {
                background: $color-white;
                padding: 0 10px;
                position: absolute;
                top: -10px;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
            }
        }
    }

    &.splash-form {
        height: 550px;
    }

    &.account-verification-form,
    &.password-verification-form {
        .otp-input {
            input {
                height: 55px;
                width: 100% !important;
                padding: 0;
                margin: 0 5px;
                border: 1px solid $color-light-grey;
                color: $color-black;

                &:focus {
                    border: 1px solid $color-green;
                }
            }
        }

        .timer {
            font-weight: 700;
            @extend .Nord-Regular;

            &.cursor {
                cursor: pointer;
            }
        }
    }
}
.btn-solid {
    display: block;
    text-decoration: none;
    background-color: $color-black;
    color: $color-white;
    padding: 1rem;
    width: 100%;
    border-radius: 10px;

    &:active {
        background-color: $color-black !important;
    }

    &.bg-blue{
        background-color: $color-blue;
        &:hover {
            background-color: $color-blue;
        }
    }

    &:hover, &:focus {
        color: $color-white;
        text-decoration: none;
        background-color: $color-black;
    }

    &:first-child:active{
        background-color: $color-black !important;
        border:none;
    }
}

.btn-transparent {
    display: block;
    text-decoration: none;
    background-color: transparent;
    color: $color-black;
    padding: 1rem;
    width: 100%;
    border: 1px solid $color-black !important;
    border-radius: 10px;

    &:hover, &:focus {
        color: $color-black;
        text-decoration: none;
        background-color: transparent;
    }

    &:active {
        background-color: transparent !important;
        color: $color-black !important;
    }

    &:first-child:active{
        color: $color-black ;
        background-color: transparent !important;
        border: 1px solid $color-black !important;
    }
}

/* XXL devices */
@media only screen and (max-width: 1600px) {
    .dashboard-layout {
        header {
            .navbar {
                .search-input {
                    min-width: auto;
                }
            }
        }
    }
}

/* XL devices */
@media only screen and (max-width: 1200px) {
    .auth-layout {
        .left-wrapper {
            display: none;
        }

        .right-wrapper {
            width: 100%;

            .wrapper-container {
                height: 100%;
            }
        }
    }

    .dashboard-layout {
        header {
            .navbar {
                .navbar-toggler {
                    padding: 0;
                    border: none;
                    outline: none;
                    box-shadow: none;
                }
            }
        }
    }

    .dashboard-sidebar {
        .offcanvas-body {
            .btn-solid {
                &.create-post-btn {
                    color: $color-white;
                    min-width: 162px;
                    width: 162px;
                    font-weight: 700;
                    font-size: 14px;
                    padding: 12px 20px;
                    width: 100%;
                    text-align: center;
                }
            }

            .search-input {
                background-color: $color-bg-light;
                min-width: 100%;
                background-image: url("data:image/svg+xml,%0A%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.57982 19.0698C4.34536 19.0698 0.0836792 14.7907 0.0836792 9.53491C0.0836792 4.27908 4.34536 0 9.57982 0C14.8143 0 19.076 4.27908 19.076 9.53491C19.076 14.7907 14.8143 19.0698 9.57982 19.0698ZM9.57982 1.39535C5.10505 1.39535 1.47336 5.05118 1.47336 9.53491C1.47336 14.0186 5.10505 17.6745 9.57982 17.6745C14.0546 17.6745 17.6863 14.0186 17.6863 9.53491C17.6863 5.05118 14.0546 1.39535 9.57982 1.39535Z' fill='%23515151'/%3E%3Cpath d='M19.3075 20.0001C19.1315 20.0001 18.9555 19.935 18.8165 19.7954L16.9636 17.935C16.6949 17.6652 16.6949 17.2187 16.9636 16.9489C17.2323 16.6791 17.677 16.6791 17.9456 16.9489L19.7985 18.8094C20.0672 19.0791 20.0672 19.5257 19.7985 19.7954C19.6596 19.935 19.4835 20.0001 19.3075 20.0001Z' fill='%23515151'/%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-position: 95% 12px;
                padding: 12px 52px 12px 20px;
            }

            a {
                color: $color-dark-grey;
                text-decoration: none;
                font-weight: 400;
                font-size: 14px;
                padding-bottom: 1rem;
                border-bottom: 1px solid $color-border;
                margin-bottom: 1rem;

                &:hover,
                &.active {
                    color: $color-black;
                    font-weight: 700;
                }
            }

            .icon-wrapper {
                min-width: 40px;
                height: 40px;
                background-color: $color-grey;
                border-radius: 50%;
                overflow: hidden;

                .icon {
                    color: $color-dark-grey;
                    font-size: 1rem;
                }

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

/* Small devices  */
@media only screen and (max-width: 576px) {
    .auth-layout {
        .right-wrapper {
            .wrapper-container {
                padding: 1rem;
            }
        }
    }

    .dashboard-layout {
        header {
            .navbar {
                .navbar-toggler {
                    margin-top: 8px;
                }

                .logo-small {
                    height: 50px;
                }

                .icon-wrapper {
                    margin-top: 8px;
                }
            }
        }
    }
}


@media only screen and (max-width: 350px) {
    .dashboard-layout{
        header{
            .navbar{
                .icon-wrapper{
                    width: 35px;
                    min-width: 35px;
                    height: 35px;
                }
            }
        }
    }
}


@media only screen and (min-width: 1600px) {
    .dashboard-layout {
        .me-xxl-20 {
            margin-right: 20px !important;
        }

        .me-xxl-45 {
            margin-right: 45px !important;
        }

        .me-xxl-75 {
            margin-right: 75px !important;
        }
    }
   
}

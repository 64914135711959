.pages {
    &.empty-home-page {
        height: calc(100vh - 85px);
    }

    &.term-and-condition-page,
    &.privacy-policy-page {
        .page-content-wrapper {
            box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
            border-radius: 25px;
            height: 80vh;
            overflow-y: auto;
            padding: 30px;

            &::-webkit-scrollbar {
                display: none;
            }

            h4{
                font-weight: 600;
            }
        }
    }

    &.about-page {
        .wrapper {
            height: calc(100vh - 185px);
            overflow: auto;
            &::-webkit-scrollbar {
                display: none;
            }
        }

        h1 {
            font-weight: 600;
        }

        .img-wrapper {
            border-radius: 25px;
            background: $color-bg;
        }

        p{
            font-size: 16px;
        }
    }

    &.contact-page{
        h1{
            color: $color-green;
        }

        .left-wrapper{
            background-color: $color-bg;
            border-radius: 25px;
            height: 555px;
            display: flex;
            align-items: center;


            p{
                font-size: 16px;
            }


            .content-container{
                .box{
                    background-color: $color-white;
                    border-radius: 10px;
                    display: inline-block;
                }
        
                .wrapper{
                    background-color: $color-bg;
                    border-radius: 25px;
                }
        
                .icon-wrapper{
                    display: inline-block;
                    background-color: $color-grey;
                    border-radius: 5px;
                    width: 35px;
                    line-height: 32px;
                    height: 35px;
                    min-width: 35px;
                    text-align: center;
                    vertical-align: middle;
                }
        
                p{
                    color:$color-dark-grey;
                    font-size: 14px;
                }
            }
    
            .logo-container{
                .box{
                    background-color: $color-white;
                    border: 1px solid $color-border;
                    width: 55px;
                    height: 55px;
                    min-width: 55px;
                    border-radius: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        .right-wrapper{
            height: 555px;
            display: flex;
            box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
            border-radius: 25px;

            textarea{
                resize: none;
                height: 175px;

                &::-webkit-scrollbar {
                    display: none;
                }

                &::placeholder{
                    color:$color-dark-grey;
                }
            }

            .box{

                .form-floating {
                    position: relative;

                    textarea{
                        height: 150px;
                        resize: none;
                    }
            
                    input {
                        transition: 0.3s ease all;
            
                        &:hover,
                        &:focus {
                            ~ .icon-wrapper {
                                background-color: $color-green;
                                .icon {
                                    color: $color-white;
                                }
                            }
                        }
                    }
            
                    .icon-wrapper {
                        transition: 0.3s ease all;
                        position: absolute;
                        top: 12px;
                        left: 12px;
                        min-width: 35px;
                        min-height: 35px;
                        background-color: $color-grey;
                        border-radius: 5px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
            
                        .icon {
                            transition: 0.3s ease all;
                            color: $color-green;
                            font-size: 14px;
                        }
                    }
            
                    .icon-wrapper-right {
                        position: absolute;
                        top: 13px;
                        right: 12px;
                        min-width: 15px;
                        min-height: 35px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
            
                        .icon {
                            color: $color-green;
                        }
                    }
                }
            }
        }
    }

    &.statistics-and-analytics-page,
    &.post-list-page{
        .folder-box {
            cursor: pointer;

            span {
                font-weight: 700;
                @extend .Nord-Regular;
                font-size: 14px;
            }
        }
        
        a{
            text-decoration:none;
            color: unset;
        }
    }

    &.error-page{
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .btn-solid{
            max-width: 325px;
            margin: auto;
        }
    }

}
.auth-layout {
    width: 100%;
    height: 100vh;
    overflow: hidden;

    .left-wrapper {
        width: 50%;
        height: 100%;
        position: relative;
        background-image: url("data:image/svg+xml,%3Csvg width='846' height='960' viewBox='0 0 846 960' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle r='580.759' transform='matrix(-1 0 0 1 264.866 461.469)' fill='%23F1F4F3'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position-x: 0;
        background-size: cover;

        img {
            width: 80%;
        }
    }

    .right-wrapper {
        width: 50%;
        .wrapper-container {
            height: 100vh;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow-x: hidden;
            padding: 1.2rem;
            overflow-y: auto;
        }
    }
}

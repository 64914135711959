@font-face {
    font-family: 'Nord-Regular';
    src: url('Nord-Regular.woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Outfit-Regular';
    src: url('Outfit-Regular.woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
/* XXL devices */
@media only screen and (max-width: 1400px) {
    .pages {
        &.term-and-condition-page,
        &.privacy-policy-page {
            .page-content-wrapper {
                height: 72vh;
            }
        }
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
    .pages {
        &.about-page {
            .wrapper {
                height: auto;
            }
        }
    }
}

/* Medium devices */
@media only screen and (max-width: 768px) {
    .pages {
        &.term-and-condition-page,
        &.privacy-policy-page {
            .page-content-wrapper {
                height: auto;
            }
        }
    }
}

/* Small devices */
@media only screen and (max-width: 576px) {
    .pages {
        &.contact-page {
            .left-wrapper {
                .logo-container {
                    .box {
                        width: 45px;
                        height: 45px;
                        min-width: 45px;
                    }
                }
            }
        }
    }
}


/* Extra small devices */
@media only screen and (max-width: 460px) {
    .pages {
        &.contact-page {
            .left-wrapper {
                .content-container {
                    .box {
                        display: flex;
                        align-items: center;
                    }

                    .icon-wrapper {
                        display: unset;
                    }

                    p {
                        display: unset;
                    }
                }
            }
        }

        &.error-page{
            .btn-solid{
                max-width: 100%;
            }
        }
    }
}





/* Extra small devices (iPhone specially) */
@media only screen and (max-width: 400px) {
    h1 {
        font-size: 28px;
    }

    h2 {
        font-size: 20px;
    }

    h3 {
        font-size: 16px;
    }

    h4 {
        font-size: 18px;
    }

    h5 {
        font-size: 16px;
    }

    h6 {
        font-size: 14px;
    }

    p,
    input,
    .form-control,
    small {
        font-size: 14px;
    }

    input,
    .form-control {
        &::placeholder {
            font-size: 14px;
        }
    }

    .form-floating {
        > label {
            opacity: 1 !important;
            font-size: 14px;
        }
    }

    span {
        font-size: 14px;
    }

    button.btn,
    a {
        font-size: 14px;
    }
}

@media only screen and (max-width: 375px) {
    h1 {
        font-size: 26px;
    }

    h4{
        font-size: 16px;
    }
}
